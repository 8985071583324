<template>
  <div id="gift-info">
    <div id="gift-avatar" :class="{ animated : gift.isAnimated, 'reward-category' : gift.isRewardCategory }">
      <img :src="gift.imageSrc"
           :alt="gift.nameDecoded"
           :title="gift.nameDecoded"
           :class="gift.animation_class"
      >
    </div>
    <div id="gift-stack">
      <div :data-stack="stack_info.stack">
        <svg width="7"
             height="9"
             viewBox="0 0 7 9"
             xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#fff" fill-rule="evenodd" d="M4.855 4.38l2.067 3.274L4.83 8.758l-1.37-2.17-1.368 2.17L0 7.654l2.067-3.275L0 1.105 2.092 0 3.46 2.17 4.83 0l2.09 1.105z" />
        </svg>
      </div>
    </div>
    <div id="gift-name">
      {{ gift.nameDecoded }}
    </div>
    <div id="gift-count" :data-count="stack_info.stack">
      Gifts
    </div>
    <div id="gift-xp" :data-xp="stack_info.xp" />
    <div id="gift-list-wrapper">
      <div id="gift-list">
        <div v-for="(act, index) in stack_info.acts" :key="index" class="gift-sender">
          <div class="gift-sender-avatar" :data-gender="act.gender" :data-nickname="getAvatarNickname(act)">
            <img v-show="act.avatar_url.length > 0" :src="act.avatar_url">
            <img v-show="act.flair_url.length > 0" :src="act.flair_url">
          </div>
          <div class="gift-sender-nickname">
            <img :src="getAlevel(act)">
            <a :href="getProfileUrl(act)" :title="getNickname(act)" :style="{color: act.color}">{{ getNickname(act) }}</a>
          </div>
          <div class="gift-sender-date">
            {{ act.date }}
          </div>
          <div class="gift-sender-xp">
            <span>+{{ act.xp }} XP</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GiftInfo",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gift: this.options.gift, //VgiftPanelItem
      stack_info: this.options.stack_info,
    }
  },
  created() {},
  methods: {
    getNickname(act) {
      return this.$helper.decode(act.display_name);
    },
    getAvatarNickname(act) {
      return this.$helper.getAvatarNickname(this.$helper.decode(act.display_name));
    },
    getAlevel(act) {
      return this.$helper.getAlevelImageSrc(act.alevel_id, 'cf7');
    },
    getProfileUrl(act) {
      if (!act.show_profile_url) {
        return '#';
      }

      let url;
      if (this.$external.data.web_view) {
        url = 'camfrog://open_user_profile/?name=' + encodeURIComponent(this.$helper.decode(act.display_name));
      } else {
        url = this.$helper.getProfileUrl(act.display_name);
      }
      return url;
    }
  }
}
</script>

<style scoped>
#gift-info {
  position: relative;
  max-width: 400px;
  width: 80vw;
  margin: 20px auto;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
}
#gift-avatar {
  position: relative;
  height: 100px;
  width: 100px;
  margin: 0 auto;
  overflow: hidden;
}
#gift-avatar.hd {
  height: 150px;
  width: 150px;
}
#gift-avatar.reward-category:after {
  content: '';
  position: absolute;
  height: 25px;
  width: 25px;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-image: url(~@/assets/images/icon_reward_points.svg);
  background-size: cover;
}
#gift-avatar > img {
  height: 100%;
}
#gift-stack {
  position: relative;
  display: block;
  height: 0;
  width: 100px;
  margin: 0 auto;
}
#gift-stack > [data-stack] {
  position: absolute;
  display: inline-block;
  height: 44px;
  min-width: 44px;
  top: -44px;
  right: -14px;
  padding: 0 10px;
  border-radius: 44px;
  box-sizing: border-box;
  background: rgb(254,227,58); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(254,227,58,1) 0%, rgba(254,197,58,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(254,227,58,1) 0%, rgba(254,197,58,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(254,227,58,1) 0%, rgba(254,197,58,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  text-align: center;
  line-height: 44px;
}
#gift-avatar.hd + #gift-stack {
  width: 150px;
}
#gift-avatar.hd + #gift-stack > [data-stack] {
  right: 0;
}
#gift-stack > [data-stack] > svg {
  display: inline-block;
  margin-right: 2px;
  vertical-align: middle;
}
#gift-stack > [data-stack]:after {
  content: attr(data-stack);
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  vertical-align: middle;
}
#gift-name {
  height: 27px;
  margin-top: 12px;
  padding: 0 30px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  color: #565656;
  text-align: center;
}
#gift-count {
  height: 17px;
  margin-top: 1px;
  font-weight: 700;
  font-size: 12px;
  color: #cacaca;
}
#gift-count:before {
  content: attr(data-count);
}
#gift-count[data-count=""],
#gift-count[data-count="0"],
#gift-count[data-count="1"] {
  display: none;
}
#gift-xp {
  position: relative;
  padding: 4px 0;
  text-align: center;
}
#gift-xp:before {
  content: '+' attr(data-xp) ' XP';
  position: relative;
  height: 30px;
  top: 19px;
  padding: 0 15px;
  border-radius: 30px;
  background: #9a48ff; /* Old browsers */
  background: -moz-linear-gradient(left, #9a48ff 0%, #9433ff 50%, #ef42ff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #9a48ff 0%, #9433ff 50%, #ef42ff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #9a48ff 0%, #9433ff 50%, #ef42ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  line-height: 30px;
}

#gift-list-wrapper {
  padding: 30px 5px 0 15px;
  border-top: 1px solid #f1f1f1;
  box-sizing: border-box;
}
#gift-list-wrapper:after {
  content: '';
  position: absolute;
  display: block;
  height: 30px;
  bottom: 0;
  left: 30px;
  right: 35px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
}
#gift-list {
  max-height: 340px;
  width: 100%;
  padding-left: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
}
#gift-list::-webkit-scrollbar {
  position: absolute;
  width: 15px;
  cursor: pointer;
}
#gift-list::-webkit-scrollbar-track {
  background-color: #fff;
}
#gift-list::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
  border-radius: 6px/8px;
  background-color: rgba(0, 0, 0, .2);
}
#gift-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .4);
}
#gift-list:after {
  content: '';
  display: block;
  height: 15px;
  width: 100%;
}
.gift-sender {
  position: relative;
  height: 70px;
  padding: 17px 16px 17px 46px;
  box-sizing: border-box;
}
.gift-sender + .gift-sender:before {
  content: '';
  position: absolute;
  display: inline-block;
  height: 1px;
  top: 0;
  left: 46px;
  right: 16px;
  background-color: #f1f1f1;
}
.gift-sender-avatar {
  position: absolute;
  height: 36px;
  width: 36px;
  top: 17px;
  left: 0;
  border-radius: 100%;
}
.gift-sender-avatar:before {
  content: attr(data-nickname);
  display: block;
  height: 100%;
  width: 100%;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
}
.gift-sender-avatar > img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
  transition: .2s;
}
.gift-sender-avatar > img + img {
  height: 20px;
  width: 20px;
  top: 19px;
  left: -3px;
  border-radius: 0;
}
.gift-sender-nickname,
.gift-sender-date {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}
.gift-sender-nickname {
  height: 20px;
  font-weight: 600;
  font-size: 0;
  line-height: 20px;
}
.gift-sender-nickname > * {
  font-size: 12px;
  vertical-align: middle;
}
.gift-sender-nickname > img {
  display: inline-block;
  height: 12px;
  margin-right: 4px;
}
.gift-sender-date {
  height: 14px;
  padding-top: 2px;
  font-weight: 400;
  font-size: 10px;
  color: #939393;
}
.gift-sender-xp {
  position: absolute;
  display: inline-block;
  height: 36px;
  top: 17px;
  right: 16px;
  padding: 10px 0 10px 5px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 0 5px 0 #fff;
}
.gift-sender-xp > span {
  display: inline-block;
  height: 16px;
  padding: 0 13px;
  border-radius: 16px;
  font-weight: 700;
  font-size: 11px;
  color: #fff;
  background-color: #9a47ff;
  line-height: 16px;
}
</style>
